.login{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-self: center;

  .form_section,
  .content{
    width: 50%;
  }
  .form_section{
    padding-top: 100px;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-flow: column nowrap;
    height: 100%;
    min-height: 100vh;
    background-color: whitesmoke;
   

    .login-form{
      min-width: 375px;
      width: auto;
      max-width: 700px;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      gap: 30px;
      
      div{
        width: 100%;
      }
    }
  }
  .content{
    background-image: url('../../../public/login-bg.svg');
    background-size: cover;
    background-position: center bottom;
    height: 100%;
    min-height: 100vh;
    padding: 20px;
    position: relative;

    h2{
      text-align: center;
      color: $c-white;
      margin:50px auto;
    }

    .slide-container{
      padding: 30px;
 
      h1{
        color: $c-white;
        font-size: 4vw;
      }
      p{
        color: $c-white;
        font-size: 2vw;
      }
    }
  }
}


@media (max-width: 800px) {
  .login{
    .form_section{
      width: 100%;
    }
    .content{
      display: none;
    }
  }
  
}