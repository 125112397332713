.navbar{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $c-primary;
  width: 80%;
  height: 80px;
  padding: 0 30px;
  margin: 0 auto;

  .dropdown{
    .user_btn {
     background: transparent;
     img{
      width: 28px;
      height: 28px;
      border-radius: 50%;
      border: 2px solid rgb(255 255 255 / 20%);
      margin-right: 5px;
     }
     .user_name{
      color: whitesmoke;

     }
  }
  }

}