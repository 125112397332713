.sf-list-item{
  border-left: 2px solid $c-main !important ;
  background-color: rgb(12 22 43);

  .card-container{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: start;
    gap: 30px;
    .icon{
      width: 80px;
    }
  }
  h2{
    color: #FFF;
  }
}