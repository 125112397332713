@import './variables/colors';
@import './variables/fonts';
@import './pages/login';
@import './pages/home';
@import './components/CustomInput';
@import './components/navbar';
@import './components/listitem';

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


body {
  font-family: "Trasandina W03 Regular Italic", sans-serif !important;
  background-color: rgba(0 8 2);
  a {
      text-decoration: none !important;
      color: $c-main;
  }
}
[dir="rtl"] body {
  font-family: "TheSansArabic Light", "TheSans", "Cairo", sans-serif !important;
}

.btn-primary{
  background: linear-gradient(261.15deg, #0994B8 -22.57%, #29807D 113.91%);
  border-radius: 6px;
  border-color: $c-main;
  &:hover{
    background: linear-gradient(200deg, rgba(9, 149, 184, 0.85) -22.57%, #29807D 113.91%);
    border-color: $c-main;

  }
}


input,
button{
  height: 48px;
  margin: 15px auto;
}

input:not(input[type='checkbox']){
  background-color: rgba(217, 217, 217, 0.35) !important;
  border-color: $c-main !important;
  padding-left: 48px !important;
  &:focus{
    border-color: $c-primary !important;
  }
}


.form-group{
  position: relative !important;
  .icon{
    position: absolute !important;
    width: 48px;
    height: 48px;
    bottom: 0px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      margin-bottom: 0;
    }
  }
}